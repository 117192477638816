<template>
  <div class="login-container"
       v-loading="wechat"
       element-loading-text="企业微信登录中..."
       element-loading-spinner="el-icon-loading">

    <el-form ref="loginForm"
             :model="loginForm"
             :rules="loginRules"
             class="login-form"
             auto-complete="on"
             label-position="left">
      <div class="title-container">
        <h3 class="title">爱选系统管理后台</h3>
      </div>

      <el-form-item prop="username">
        <span class="svg-container">
          <!-- <svg-icon icon-class="user" /> -->
          <i class="el-icon-s-custom" />
        </span>
        <el-input ref="username"
                  v-model="loginForm.username"
                  placeholder="请输入用户名"
                  name="username"
                  type="text"
                  tabindex="1"
                  auto-complete="on" />
      </el-form-item>

      <el-form-item prop="password">
        <span class="svg-container">
          <i class="el-icon-lock" />
          <!-- <svg-icon icon-class="password" /> -->
        </span>
        <el-input :key="passwordType"
                  ref="password"
                  v-model="loginForm.password"
                  :type="passwordType"
                  placeholder="请输入密码"
                  name="password"
                  tabindex="2"
                  auto-complete="on" />
        <span class="show-pwd"
              @click="showPwd">
          <i class="el-icon-view" />
          <!-- <svg-icon :icon-class="passwordType === 'password' ? 'eye' : 'eye-open'" /> -->
        </span>
      </el-form-item>

      <el-form-item prop="captcha">
        <span class="svg-container">
          <!-- <svg-icon icon-class="user" /> -->
          <i class="el-icon-picture" />
        </span>
        <el-input ref="username"
                  v-model="loginForm.captcha"
                  placeholder="请输入图形验证码"
                  name="username"
                  type="text"
                  tabindex="3"
                  @keyup.enter.native="handleLogin" />
        <img v-loading="captchaLoading"
             class="captcha"
             :src="captchaData"
             alt="captcha"
             @click="queryLoginCaptcha" />
      </el-form-item>

      <el-button :loading="loading"
                 type="primary"
                 style="width:100%;margin-bottom:30px;"
                 @click.native.prevent="handleLogin">登 录</el-button>
      <el-button @click="weixinLogin">微信登录</el-button>
      <div class="other-login">
        <span @click="qrLogin">企业微信登录</span>
      </div>
    </el-form>
    <el-dialog title="企业微信登录"
               :visible.sync="qrLoginShow"
               width="30%"
               :before-close="handleQrClose">
      <div id="qrCode" />
      <!-- <span slot="footer" class="dialog-footer">
        <el-button @click="handleQrClose">取 消</el-button>
        <el-button type="primary" @click="handleQrClose">确 定</el-button>
      </span> -->
    </el-dialog>

  </div>
</template>

<script>
import { queryLoginCaptcha } from "@/api/user.js"
import { getRandomAlphaNum } from "@/utils/index"
// import { registerApps } from '@/appRegister'
export default {
  name: "LoginDebug",
  data() {
    return {
      loginForm: {
        username: "",
        password: "",
        captchaKey: "",
        captcha: ""
      },
      captchaData: "",
      loginRules: {
        username: [{ required: true, trigger: "blur" }],
        password: [{ required: true, trigger: "blur" }],
        captcha: [{ required: true, trigger: "blur" }]
      },
      loading: false,
      passwordType: "password",
      redirect: undefined,
      captchaLoading: false,
      qrLoginShow: false,
      random: "",
      wechat: false,
      pageUrl: ""
    }
  },
  watch: {
    $route: {
      handler(route) {
        this.redirect = route.query && route.query.redirect
      },
      immediate: true
    }
  },
  created() {
    console.log(window.location.href)
    this.pageUrl = window.location.href
    this.queryLoginCaptcha()
  },
  methods: {
    async queryLoginCaptcha() {
      this.captchaLoading = true
      const res = await queryLoginCaptcha()
      this.loginForm.captchaKey = res.data.captchaKey
      this.captchaData = res.data.captchaData
      this.captchaLoading = false
    },
    showPwd() {
      if (this.passwordType === "password") {
        this.passwordType = ""
      } else {
        this.passwordType = "password"
      }
      this.$nextTick(() => {
        this.$refs.password.focus()
      })
    },
    handleLogin() {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          this.loading = true
          this.$store
            .dispatch("user/login", this.loginForm)
            .then((spaces) => {
              console.log(spaces.length)
              if (spaces.length === 1) {
                this.skipSpace(spaces[0].spaceId)
                localStorage.setItem("spaceId", spaces[0].spaceId)
              } else {
                this.$router.replace("/login-success")
              }
              this.loading = false
            })
            .catch(() => {
              this.queryLoginCaptcha()
              this.loading = false
              this.loginForm.captcha = ""
            })
        } else {
          console.log("error submit!!")
          return false
        }
      })
    },

    qrLogin() {
      this.qrLoginShow = true
      setTimeout(() => {
        this.getLoginQrcode()
      }, 200)
    },
    weixinLogin() {
      const { code, state } = this.$route.query

      this.weChatLogin(code, state)
      this.wechat = true
    },
    weChatLogin(code, state) {
      this.$store
        .dispatch("user/weChatLogin", { code, state })
        .then((spaces) => {
          console.log(spaces)
          if (spaces.length === 1) {
            this.skipSpace(spaces[0].spaceId)
            localStorage.setItem("spaceId", spaces[0].spaceId)
          } else {
            this.$router.replace("/login-success")
          }
          this.wechat = false
        })
    },
    skipSpace(id) {
      this.$store.dispatch("user/loginSpace", id).then((res) => {
        this.$router.push(res)
      })
    },
    handleQrClose() {
      this.qrLoginShow = false
    },
    getLoginQrcode() {
      const random = getRandomAlphaNum(10)
      this.random = random
      console.log(random)
      // http://boss.portal.aixbx.com/login?code=Xxe3SzzkVIgmNVXu8djdxcBPbbBSSk6IWiCYUriwv_o&state=&appid=ww5fc93f267b956282
      const redirectUri = encodeURIComponent(this.pageUrl)
      var wwLogin = new WwLogin({
        id: "qrCode",
        appid: "ww5fc93f267b956282",
        agentid: "1000023",
        redirect_uri: redirectUri,
        state: random,
        href: "//boss.portal.aixbx.com/css/app.css",
        lang: "zh"
      })
    }
  }
}
</script>

<style lang="scss">
/* 修复input 背景不协调 和光标变色 */
/* Detail see https://github.com/PanJiaChen/vue-element-admin/pull/927 */

$bg: #283443;
$light_gray: #fff;
$cursor: #fff;

@supports (-webkit-mask: none) and (not (cater-color: $cursor)) {
  .login-container .el-input input {
    color: $cursor;
  }
}

/* reset element-ui css */
.login-container {
  .el-input {
    display: inline-block;
    height: 47px;
    width: 85%;

    input {
      background: transparent;
      border: 0px;
      -webkit-appearance: none;
      border-radius: 0px;
      padding: 12px 5px 12px 15px;
      color: $light_gray;
      height: 47px;
      caret-color: $cursor;

      &:-webkit-autofill {
        box-shadow: 0 0 0px 1000px $bg inset !important;
        -webkit-text-fill-color: $cursor !important;
      }
    }
  }

  .el-form-item {
    border: 1px solid rgba(255, 255, 255, 0.1);
    background: rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    color: #454545;
  }
}
</style>

<style lang="scss" scoped>
$bg: #2d3a4b;
$dark_gray: #889aa4;
$light_gray: #eee;

.login-container {
  min-height: 100%;
  width: 100%;
  background-color: $bg;
  overflow: hidden;
  color: #fff;

  .login-form {
    position: relative;
    width: 520px;
    max-width: 100%;
    padding: 160px 35px 0;
    margin: 0 auto;
    overflow: hidden;
  }

  .tips {
    font-size: 14px;
    color: #fff;
    margin-bottom: 10px;

    span {
      &:first-of-type {
        margin-right: 16px;
      }
    }
  }

  .svg-container {
    padding: 6px 5px 6px 15px;
    color: $dark_gray;
    vertical-align: middle;
    width: 30px;
    display: inline-block;
  }

  .title-container {
    position: relative;

    .title {
      font-size: 26px;
      color: $light_gray;
      margin: 0px auto 40px auto;
      text-align: center;
      font-weight: bold;
    }
  }

  .show-pwd {
    position: absolute;
    right: 10px;
    top: 7px;
    font-size: 16px;
    color: $dark_gray;
    cursor: pointer;
    user-select: none;
  }
  .captcha {
    position: absolute;
    right: 5px;
    top: 10px;
    cursor: pointer;
  }
  .other-login {
    text-align: center;
    span {
      cursor: pointer;
    }
  }
  #qrCode {
    width: 300px;
    height: 400px;
    background-color: #fff;
    margin: 0 auto;
  }
}
</style>
